import React from "react";
import MainLayout from "../../layouts/MainLayout";
import { Helmet } from "react-helmet";

const Help = () => {
  return (
    <MainLayout>
      {/* Set SEO Metadata and Canonical Tag */}
        <Helmet>
          <title>Help | :paralegal.lk</title>
          <meta
            name="description"
            content="Learn to search more effectively on :paralegal.lk, find exactly the case law you need."
          />
          <link rel="canonical" href="https://www.paralegal.lk/help" />
        </Helmet>

      <div className="max-w-4xl mx-auto py-12 px-6 space-y-8 text-pl_txt_light_4 dark:text-pl_txt_dark_2">
        {/* Main Title */}
        <div className="border-b pb-6">
          <h1 className="text-3xl font-bold mb-4">
            Getting more out of{" "}
            <a href="https://paralegal.lk" className="text-pl_prim_color_1 hover:underline">
              paralegal.lk
            </a>
          </h1>
          
          <div className="space-y-4 text-lg">
            <p>Our Sri Lankan caselaw search engine is still a work in progress and our end goal is to retrieve the most relevant court decision no matter the query, instantly . . . with you doing as little work as possible.</p>
            <p>Till we get there, however, there are ways to get paralegal.lk to work better for you by doing some extra work.</p>
            <p>In this page, we give a brief overview of the mechanics of the search engine and then list a few general strategies you may follow to get more relevant results.</p>
          </div>
        </div>

        {/* How it Works Section */}
        <section>
          <h2 className="text-2xl font-semibold mb-6">How does paralegal.lk work?</h2>
          <p className="mb-6">Understanding how the search engine works can help you make more informed decisions while you use it.</p>
          
          <h3 className="text-xl font-semibold mb-4">Search Modes</h3>
          <ul className="list-disc pl-6 space-y-3 mb-6">
            <li>Hybrid Search (All): Combines exact and semantic matching to provide a balanced set of results from court decisions.</li>
            <li>Exact Search: Matches queries word-for-word across the entire collection of court decisions, similar to a Ctrl+F search.</li>
            <li>Semantic Search: Matches queries at the meaning level, identifying related concepts and synonyms in court decisions.</li>
            <li>Case Number Search: Allows precise searches using formatted or unformatted case numbers (e.g., "70 NLR 133" or "SC/FR/413/2021").</li>
            <li>Parties Search: Search only on parties; narrows results to cases where specified parties are directly involved, excluding mere citations.</li>
          </ul>

          <div className="space-y-4 mb-8">
            <p>By default, paralegal.lk employs a hybrid of exact and semantic matching. The results that you see under the 'All' tab comes from this scheme. You can also, depending on the nature of the query, independently do an exact or semantic match by selecting the respective modes.</p>
            <p>Exact search matches queries word-for-word against court decisions. This mode is very — although, not exactly — similar to Ctrl+F search on documents that we use regularly. Except, in this case, you are doing such a matching against the whole collection of court decisions as opposed to a single document.</p>
            <p>Semantic search mode matches queries against decisions at the semantic level. For instance, under this mode, you might expect the query "Can an employer fire an employee without notice in Sri Lanka?" to match with a decision that contains the sentence "Under Sri Lankan labour law, dismissing an employee requires giving adequate notice unless there is just cause for immediate termination." Though the words "fire" and "without notice" do not appear in the decision, the semantic match happens because "fire" is related to "dismiss" and "without notice" is captured by "requires giving adequate notice." Under this mode, we match the meaning of the query not word strings. </p>
            <p>In addition to the above search modes, Paralegal also offers two specialised search modes to match against case numbers or parties.</p>
            
            <p>While earlier it was a tad too difficult to search by case number on https://paralegal.lk, we have greatly simplified this now. </p>
            <p>You can simply search for any of the following:</p>
            <ul className="list-disc pl-6 space-y-2">
              <li>70 nlr 133</li>
              <li>70/nlr/133</li>
              <li>sc fr 413 2021</li>
              <li>SC/FR/413/2021</li>
              <li>2014 1 sri lr 44</li>
              
            </ul>
            <p>. . . and get exactly what you are looking for.</p>
            <p>Parties match allows you to search on your region of interest. Let's say, you are looking for a decision where 'People's Bank' was a party. A full decision text search (under the default 'All' tab) will also return results where (older) decisions involving 'People's Bank' are merely cited without the bank being a party to the matter at hand. Searching on Parties, instead, will help you significantly narrow down your results.
            </p>
          </div>
        </section>

        {/* Filters Section */}
        <section>
          <h3 className="text-xl font-semibold mb-4">Filters</h3>
          <p className="mb-4">Filters allow you to drill down into the results based on additional information you may have related to the result that you are looking for.</p>
          <p>:paralegal.lk offers a comprehensive range of filters to help users refine their search results and locate the most relevant court decisions efficiently. Filters such as court (Supreme Court or Court of Appeal), report type (e.g., NLR, Sri-LR) and volume allow users to narrow their search to specific appellate court or legal publication.</p>
          <p>Additionally, filters like judge, year, month, and action enable a more targeted search based on the presiding authority, time of decision, or specific procedural actions taken in a case. By combining these filters with the engine’s results or independently, users can drill down through large datasets to quickly access decisions that are most relevant to them. </p>
        
        </section>

        {/* Strategies Section */}
        <section>
          <h2 className="text-2xl font-semibold mb-6">Strategies to make paralegal.lk work better for you</h2>
          
          <div className="space-y-6">
            <div>
              <h3 className="text-xl font-semibold mb-4">Choose the right search mode based on query type</h3>
              <p className="mb-4">Some queries are more suited for semantic match while others are most suited for exact match. Here are some examples of conceptual queries:</p>
              <ul className="list-disc pl-6 space-y-2 mb-4">
                <li>What is the test for fundamental rights violations?</li>
                <li>Can a public servant engage in political activities?</li>
                <li>requirements for valid will in Sri Lanka</li>
              </ul>
              <p className="mb-4">Consider the query ‘requirements for a valid will in Sri Lanka’. It is a tricky one because ‘will’ has multiple meanings. While in legal parlance, ‘will’ stands for a testamentary document outlining the distribution of a person’s assets after death, it is also the commonly used modal verb used to talk about what is going to happen in the future. Given that ‘will’ is an extremely frequently used word, one that is possibly present in every court decision, string (exact) matching on that word is unlikely to return relevant results. Which is why switching to semantic mode is a sound choice for this query. 
              </p>
              <p className="mb-4">Conversely, if you’re looking for specific legal phrases or terminology, you may be better served by exact search. For example: 
              </p>
              <ul className="list-disc pl-6 space-y-2 mb-4">
                <li>"uberrima fides"</li>
                <li>"ejusdem generis"</li>
              </ul>
              <p className="mb-4">Similarly, exact search is also the more suitable mode for searching for quoted text from judgments or specific statutory provisions like:
              </p>
              <ul className="list-disc pl-6 space-y-2 mb-4">
                <li>"section 146 civil procedure code"</li>
              </ul>

            </div>
            <div>
              <h3 className="text-xl font-semibold mb-4">Use filters strategically</h3>
              <p className="mb-4">
Our engine presently does not return highly relevant results for the query ‘requirements for a valid will’  under the default mode. Switching to semantic mode helps, as discussed above. But you can get even better results by filtering down to reported judgments in Sri Lanka Law Reports or New Law Reports. Especially for conceptual queries, internally at least, we find that restricting to reported decisions helps us get to relevant results much faster. 
</p> 
            </div>

            <div>
              <h3 className="text-xl font-semibold mb-4">Query rephrasing</h3>
              <p className="mb-4">Consider the query</p>
              <ul className="list-disc pl-6 space-y-2">
                <li>Have trade unions instituted FR applications in the SC?</li>
              </ul>
              <p className="mb-4">The most relevant decision to this question is sc-fr-15/2015, in which the Supreme Court held that Article 126 does not empower unincorporated bodies such as trade unions to file fundamental rights applications. But, the query, phrased exactly as above, does not surface the correct decision.</p>
              <p className="mb-4">However, if you rephrased the query to ‘Have trade unions instituted fundamental rights applications in the Supreme Court?’, the correct decision will be the second ranked result under semantic mode. 
              </p>
              <p className="mb-4">The difference is in ‘FR’ vs. ‘Fundamental rights’.  So if you are using abbreviations or shorthands, consider using their expanded forms. Generally, bias towards more descriptive queries, especially under semantic mode.</p>
              <p className="mb-4">In a similar vein, choose technical language over everyday terms:</p>
              <ul className="list-disc pl-6 space-y-2">
                <li>Instead of: "Can I kick out my tenant?"</li>
                <li>Use: "ejectment of tenant" or "termination of lease"</li>
              </ul>
              <p>In ‘requirements for a valid last will’, replacing ‘will’ with ‘testamentary document’ would retrieve much more relevant decisions, even under the default mode. </p>
            </div>
            <div>
              <h3 className="text-xl font-semibold mb-4">Partial query matching in Exact mode</h3>
              <p className="mb-4">
              In place of long queries under Default mode, take key (partial) phrases and run them through Exact mode.  
              </p>
              <p className="mb-4">
                Example:
              </p>
              <ul className="list-disc pl-6 space-y-2">
                  <li>In place of searching for the full query "Conveyance of land to person other than the one to whom owner is under agreement to sell" under Default mode. </li>
                  <li>Search for "conveyance of land" or “person other than the one to whom owner is under agreement to sell” in Exact mode.</li>
              </ul>

            </div>
            <div>
            <h3 className="text-xl font-semibold mb-4">Cross-reference results
            </h3>
            <p className="mb-4">Generally:</p>
            <ul className="list-disc pl-6 space-y-2">
                  <li>Try the same query in different modes. </li>
                  <li>Compare results across modes.</li>
                  <li>Look for cases that appear as top results under multiple modes,</li>
              </ul>


            </div>

          </div>
        </section>

        {/* Footer */}
        <footer className="pt-8 border-t">
          <p className="text-lg">
            Error, feedback, feature request:{" "}
            <a
              href="mailto:admin@paralegal.lk"
              className="text-pl_prim_color_1 hover:underline"
            >
              admin@paralegal.lk
            </a>
          </p>
        </footer>
      </div>
    </MainLayout>
  );
};

export default Help;