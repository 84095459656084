import React from 'react';
import PaymentButton from './payment/paymentButton';

const Subscription = () => {
  return (
    <div className="bg-pl_bg_light_1 dark:bg-pl_bg_dark_1 text-pl_txt_light_1 dark:text-pl_txt_dark_1 flex flex-col items-center justify-center px-6 md:px-10 my-50">
      <h1 className="text-28 font-medium mb-20">Choose Your Plan</h1>
      <div className="flex flex-wrap justify-center gap-20 max-w-7xl w-full">
        {/* Annual Subscription */}
        <div className="text-14 bg-pl_bg_light_3 dark:bg-pl_bg_dark_3 rounded-10 shadow-md border border-pl_border_light_1 dark:border-pl_border_dark_1 overflow-hidden dark:text-pl_txt_dark_1 text-pl_txt_light_4 flex flex-col max-w-80 min-w-60 p-30">
          <h2 className="text-xl font-semibold text-pl_prim_color_1 mb-5">
            Annual Subscription
          </h2>
          <p className="text-16 mb-5">Best value for regular users.</p>
          <p className="text-36 font-semibold mb-5">
            Rs. 24,000<span className="text-16 font-normal">/year</span>
          </p>
          <PaymentButton DurationTime="1 Year" userType="Pro" />
          <ul className="text-14 space-y-3 flex-grow text-left my-20 list-none">
            <li className="flex">
              <span className="text-pl_prim_color_1 font-bold">✔</span>
              <span className="ml-2">
                Unlimited access to the case law database
              </span>
            </li>
            <li className="flex">
              <span className="text-pl_prim_color_1 font-bold">✔</span>
              <span className="ml-2">Priority support</span>
            </li>
            <li className="flex">
              <span className="text-pl_prim_color_1 font-bold">✔</span>
              <span className="ml-2">Exclusive features and updates</span>
            </li>
            <li className="flex">
              <span className="text-pl_prim_color_1 font-bold">✔</span>
              <span className="ml-2">
                Save Rs. 6,000 compared to the monthly plan
              </span>
            </li>
          </ul>
        </div>

        {/* Monthly Subscription */}
        <div className="text-14 bg-pl_bg_light_3 dark:bg-pl_bg_dark_3 rounded-10 shadow-md border border-pl_border_light_1 dark:border-pl_border_dark_1 overflow-hidden dark:text-pl_txt_dark_1 text-pl_txt_light_4 flex flex-col max-w-80 min-w-60 p-30">
          <h2 className="text-xl font-semibold text-pl_prim_color_1 mb-5">
            Monthly Subscription
          </h2>
          <p className="text-16 mb-5">Flexible and affordable.</p>
          <p className="text-36 font-semibold mb-5">
            Rs. 2,500<span className="text-16 font-normal">/month</span>
          </p>
          <PaymentButton DurationTime="1 Month" userType="Pro" />
          <ul className="text-14 space-y-3 flex-grow text-left my-20 list-none">
            <li className="flex">
              <span className="text-pl_prim_color_1 font-bold">✔</span>
              <span className="ml-2">Full access to the case law database</span>
            </li>
            <li className="flex">
              <span className="text-pl_prim_color_1 font-bold">✔</span>
              <span className="ml-2">
                Flexibility to subscribe on a monthly basis
              </span>
            </li>
          </ul>
        </div>

        {/* Day Pass */}
        <div className="text-14 bg-pl_bg_light_3 dark:bg-pl_bg_dark_3 rounded-10 shadow-md border border-pl_border_light_1 dark:border-pl_border_dark_1 overflow-hidden dark:text-pl_txt_dark_1 text-pl_txt_light_4 flex flex-col max-w-80 min-w-60 p-30">
          <h2 className="text-xl font-semibold text-pl_prim_color_1 mb-5">
            Day Pass
          </h2>
          <p className="text-16 mb-5">Ideal for occasional users.</p>
          <p className="text-36 font-semibold mb-5">
            Rs. 500<span className="text-16 font-normal">/day</span>
          </p>
          <PaymentButton DurationTime="1 Day" userType="Pro" />
          <ul className="text-14 space-y-3 flex-grow text-left my-20 list-none">
            <li className="flex">
              <span className="text-pl_prim_color_1 font-bold">✔</span>
              <span className="ml-2">
                24-hour access to the case law database
              </span>
            </li>
            <li className="flex">
              <span className="text-pl_prim_color_1 font-bold">✔</span>
              <span className="ml-2">Perfect for occasional users</span>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Subscription;
