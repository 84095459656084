import { useEffect } from "react";
import MainLayout from "../../layouts/MainLayout";
import Subscription from "../Subscription/Subscription";

const Pricing = () => {
  useEffect(() => {
    const storedDarkMode = localStorage.getItem("darkMode") === "true";
    document.documentElement.classList.toggle("dark", storedDarkMode);
  }, []);
  return (
    <MainLayout>
      <div className="flex flex-col justify-center items-center mx-auto w-full max-w-7xl mt-50 p-20 text-pl_txt_light_4 dark:text-pl_txt_dark_2">
        <div className="flex flex-col items-center justify-center">
          <h1 className="mb-4 font-semibold text-24">Pricing Plans</h1>
          <p className="leading-relaxed mb-2 text-20">
            Unlock the Power of{" "}
            <a
              href="https://www.paralegal.lk"
              className="font-semibold bg-gradient-to-br from-[#dd0c7e] via-[#ca1582] to-[#a6208e] text-transparent bg-clip-text"
            >
              :paralegal.lk
            </a>
          </p>
          <p className="leading-relaxed mb-2 text-center max-w-3xl">
            Access the most comprehensive and accurate case law search engine in
            Sri Lanka. Choose the subscription plan that suits your needs and
            start making smarter legal decisions today.
          </p>
        </div>
        <Subscription />
        <div className="border-t border-pl_border_light_1 dark:border-pl_border_dark_1 overflow-hidden dark:text-pl_txt_dark_1 text-pl_txt_light_4 p-20">
          <h2 className="font-semibold text-16 md:text-18">
            Why Subscribe to{" "}
            <a
              href="https://www.paralegal.lk"
              className="font-bold bg-gradient-to-br from-[#dd0c7e] via-[#ca1582] to-[#a6208e] text-transparent bg-clip-text"
            >
              :paralegal.lk
            </a>
            ?
          </h2>
          <ul className="list-disc list-inside leading-relaxed mb-2 [text-indent:-1.5rem] [padding-left:2rem]">
            <li>
              Stay ahead with instant access to Sri Lanka’s most extensive case
              law repository.
            </li>
            <li>Save time with our advanced search and filtering tools.</li>
            <li>
              Gain confidence with reliable, up-to-date legal information.
            </li>
          </ul>
        </div>

        {/* <p className="mt-4">
          <strong>Ready to Get Started?</strong>{' '}
          <a
            className="hover:underline text-pl_prim_color_1"
            href="/subscription"
          >
            Subscribe Now
          </a>{' '}
          and unlock unparalleled legal research capabilities.
        </p> */}
      </div>
    </MainLayout>
  );
};

export default Pricing;
