import { useEffect } from 'react';
import MainLayout from '../../layouts/MainLayout';
import { Helmet } from 'react-helmet';
import TermsContent from './TermsContent';

const Terms = () => {
  useEffect(() => {
    const storedDarkMode = localStorage.getItem('darkMode') === 'true';
    document.documentElement.classList.toggle('dark', storedDarkMode);
  }, []);
  return (
    <MainLayout>
      <Helmet>
        <title>Terms | :paralegal.lk</title>
        <meta
          name="description"
          content="Read our terms of service to understand the conditions for using :paralegal.lk, a search engine for Sri Lankan law."
        />
        <link rel="canonical" href="https://www.paralegal.lk/terms" />
      </Helmet>

      <div className="flex flex-col justify-center mx-auto w-full max-w-6xl mt-50 p-20 text-pl_txt_light_4 dark:text-pl_txt_dark_2">
        <TermsContent />
      </div>
    </MainLayout>
  );
};

export default Terms;
