import React from 'react';
import Copyright from './Copyright';
import { useNavigate } from 'react-router-dom';

const Footer = () => {
  const navigate = useNavigate();

  const handleStatsClick = () => {
    window.location.href = '/stats';
  };

  const handlePerformanceClick = () => {
    window.location.href = '/benchmark';
  };

  return (
    <div className="bg-pl_bg_light_1 dark:bg-pl_bg_dark_3 border-t dark:border-pl_border_dark_1 border-pl_border_light_1 py-10 px-20">
      <div className="flex justify-between items-center text-14 text-pl_txt_light_2 dark:text-pl_txt_dark_1 font-light">
        <div className="flex gap-10">
          {/* Left side container (visible on larger screens only) */}
          <div className="md:flex flex-row justify-start items-center gap-[30px] w-auto hidden">
            <div
              className="flex-none order-0 flex-grow-0 h-[23px] leading-[22px] cursor-pointer"
              onClick={handlePerformanceClick}
            >
              Performance
            </div>
            <div
              className="flex-none order-1 flex-grow-0 h-[23px] leading-[22px] cursor-pointer"
              onClick={handleStatsClick}
            >
              Stats
            </div>
            <div
              className="flex-none order-2 flex-grow-0 h-[23px] leading-[22px] cursor-pointer"
              onClick={() => navigate('/help')}
            >
              Help
            </div>
            <div
              className="flex-none order-3 flex-grow-0 h-[23px] leading-[22px] cursor-pointer"
              onClick={() => navigate('/about')}
            >
              About
            </div>
          </div>
        </div>
        <div className="flex gap-10">
          <div
            className="flex-none order-0 flex-grow-0 h-[23px] leading-[22px] cursor-pointer"
            onClick={() => navigate('/careers')}
          >
            Careers
          </div>
          <div
            className="flex-none order-0 flex-grow-0 h-[23px] leading-[22px] cursor-pointer"
            onClick={() => navigate('/pricing')}
          >
            Pricing
          </div>
          <div
            className="flex-none order-0 flex-grow-0 h-[23px] leading-[22px] cursor-pointer"
            onClick={() => navigate('/refund')}
          >
            Refund
          </div>
          <div
            className="flex-none order-0 flex-grow-0 h-[23px] leading-[22px] cursor-pointer"
            onClick={() => navigate('/privacy')}
          >
            Privacy
          </div>
          <div
            className="flex-none order-1 flex-grow-0 h-[23px] leading-[22px] cursor-pointer"
            onClick={() => navigate('/terms')}
          >
            Terms
          </div>
        </div>
      </div>
      <Copyright />
    </div>
  );
};

export default Footer;
