import { Helmet } from 'react-helmet';
import MainLayout from '../../layouts/MainLayout';

const Career = () => {
  return (
    <MainLayout>
      {/* Set SEO Metadata and Canonical Tag */}
        <Helmet>
          <title>Careers | :paralegal.lk</title>
          <meta
            name="description"
            content="Join the :paralegal.lk team! We are looking for talented individuals to help us build the future of legal research in Sri Lanka."
          />
          <link rel="canonical" href="https://www.paralegal.lk/careers" />
        </Helmet>

      <div className="flex flex-col justify-center mx-auto w-full max-w-6xl mt-50 p-20 text-pl_txt_light_4 dark:text-pl_txt_dark_2">
        <h1 className="mb-4 font-bold text-22 text-center">
          Call for Internships in Software Engineering and Machine Learning at
          paralegal.lk
        </h1>
        <section className="mb-6">
          <h2 className="font-semibold text-18 mb-2">Who are we?</h2>
          <p className="leading-relaxed mb-4">
            We are a legal information technology company transforming how
            lawyers and lay people access legal information in Sri Lanka. Our
            first product{' '}
            <a
              href="https://paralegal.lk"
              className="font-bold bg-gradient-to-br from-[#dd0c7e] via-[#ca1582] to-[#a6208e] text-transparent bg-clip-text"
            >
              paralegal.lk
            </a>
            , a fast and powerful legal search engine, is loved by more than
            150+ lawyers and has supported thousands of legal searches over the
            past six months.
          </p>
        </section>
        <section className="mb-6">
          <h2 className="font-semibold text-18 mb-2">Why intern with us?</h2>
          <p className="leading-relaxed mb-4">
            We make sure that all our interns work across our technology stack
            which includes:
          </p>
          <ul className="list-disc list-inside leading-relaxed mb-4 [text-indent:-1.5rem] [padding-left:3.5rem]">
            <li>
              <strong>Python and friends:</strong> PyTorch, Pandas, NumPy, etc.
              for machine learning, natural language processing, and data
              science.
            </li>
            <li>
              <strong>JavaScript and friends:</strong> Node.js, React.js, etc.
              for web application development.
            </li>
            <li>
              <strong>Databases:</strong> MongoDB and DynamoDB for database
              requirements.
            </li>
            <li>
              <strong>Cloud services:</strong> AWS and GCP for cloud services.
            </li>
            <li>
              <strong>Web serving:</strong> Nginx for web serving.
            </li>
            <li>
              <strong>Payment integration:</strong> International payment
              gateways for transaction processing.
            </li>
          </ul>
          <p className="leading-relaxed mb-4">
            Our interns do not work on toy problems. Within a few weeks, we get
            them contributing production code.
          </p>
        </section>
        <section className="mb-6">
          <h2 className="font-semibold text-18 mb-2">How to apply?</h2>
          <p className="leading-relaxed mb-4">
            Interested candidates are encouraged to submit their resumes along
            with a cover letter detailing their interest in the internship and
            relevant skills. Please send your applications to{' '}
            <a
              href="mailto:admin@paralegal.lk"
              className="hover:underline text-pl_prim_color_1"
            >
              admin@paralegal.lk
            </a>
            .
          </p>
        </section>
      </div>
    </MainLayout>
  );
};

export default Career;
